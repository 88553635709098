import Axios from 'axios'
import store from '../store'

const BASE = '/api/v1'

export default {
  getConfiguration () {
    return {
      headers: {
        Authorization: `Bearer ${store.getters['auth/authToken']}`,
        'X-Tenant-Id': store.getters['tenant/tenant'] === null ? undefined : store.getters['tenant/tenant'].id
      }
    }
  },
  async get (path) {
    return (await Axios.get(`${BASE}/${path}`, this.getConfiguration())).data
  },
  async head (path) {
    return (await Axios.head(`${BASE}/${path}`, this.getConfiguration())).data
  },
  async put (path, o) {
    return (await Axios.put(`${BASE}/${path}`, o, this.getConfiguration())).data
  },
  async patch (path, o) {
    return (await Axios.patch(`${BASE}/${path}`, o, this.getConfiguration())).data
  },
  async post (path, o) {
    return (await Axios.post(`${BASE}/${path}`, o, this.getConfiguration())).data
  },
  async delete (path) {
    return (await Axios.delete(`${BASE}/${path}`, this.getConfiguration())).data
  }
}
