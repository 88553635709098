import Vue from 'vue'
import Vuex from 'vuex'
import accounts from './modules/accounts'
import auth from './modules/auth'
import dialog from './modules/dialog'
import self from './modules/self'
import tenant from './modules/tenant'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    accounts,
    auth,
    dialog,
    self,
    tenant
  }
})
