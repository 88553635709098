import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// /guest/s/n4i9w0q5/?ap=78:8a:20:86:c1:5a&id=16:0c:0a:95:92:5b&t=1616762821&url=http://connectivitycheck.gstatic.com%2fgenerate_204&ssid=SpinkNET+Guest

const routes = [
  {
    path: '/',
    component: () => import('../views/main'),
    meta: {
      layout: 'MainLayout'
    }
  },
  {
    path: '/guest/s/:site',
    name: 'guest-login',
    component: () => import('../views/guest-login'),
    meta: {
      layout: 'GuestLoginLayout',
      requireAuth: false
    }
  },
  {
    path: '/guest/s/:site/terms-and-conditions',
    name: 'tcs',
    component: () => import('../views/guest-login/Terms'),
    meta: {
      layout: 'GuestLoginLayout',
      requireAuth: false
    }
  },
  {
    path: '/:tenantCode',
    component: () => import('../views/tenant'),
    redirect: { name: 'dashboard' },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/tenant/dashboard')
      },
      {
        path: 'logs',
        name: 'logs',
        redirect: { name: 'logs-system' },
        component: () => import('../views/tenant/logs'),
        meta: {
          breadcrumbs: [
            { title: 'Logs' }
          ]
        },
        children: [
          {
            path: 'system',
            name: 'logs-system',
            component: () => import('../views/tenant/logs/System'),
            meta: {
              breadcrumbs: [
                { title: 'Logs' },
                { title: 'System' }
              ]
            }
          },
          {
            path: 'auth',
            name: 'logs-auth',
            component: () => import('../views/tenant/logs/Auth'),
            meta: {
              breadcrumbs: [
                { title: 'Logs' },
                { title: 'Authentication' }
              ]
            }
          }
        ]
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: () => import('../views/tenant/accounts'),
        meta: {
          breadcrumbs: [
            { title: 'Accounts' }
          ]
        }
      },
      {
        path: 'accounts/sync',
        name: 'sync-accounts',
        component: () => import('../views/tenant/accounts/sync'),
        meta: {
          breadcrumbs: [
            { title: 'Accounts', to: 'accounts' },
            { title: 'Synchronise' }
          ]
        }
      },
      {
        path: 'accounts/:accountId',
        name: 'edit-account',
        component: () => import('../views/tenant/accounts/edit'),
        meta: {
          breadcrumbs: [
            { title: 'Accounts', to: 'accounts' },
            { title: 'Account Editor' }
          ]
        }
      },
      {
        path: 'guest-access',
        name: 'guest-access',
        component: () => import('../views/tenant/guest-access'),
        meta: {
          breadcrumbs: [
            { title: 'Guest Access' }
          ]
        }
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/tenant/login'),
        meta: {
          layout: 'LoginLayout',
          requireAuth: false
        }
      },
      {
        path: 'login/reset',
        name: 'reset-password',
        component: () => import('../views/tenant/login/Reset'),
        meta: {
          layout: 'LoginLayout',
          requireAuth: false
        }
      },
      {
        path: 'login/enrol',
        name: 'enrol',
        component: () => import('../views/tenant/login/Enrol'),
        meta: {
          layout: 'LoginLayout',
          requireAuth: false
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

export default router
