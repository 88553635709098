<template>
  <component v-if="layoutName" :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'DefaultLayout'

export default {
  data: () => ({
    layoutName: null
  }),
  watch: {
    '$route' (incomingRoute) {
      this.checkRouteLayout(incomingRoute)
    }
  },
  computed: {
    layout () {
      console.log('layout', this.layoutName)
      return () => import('@/layouts/' + this.layoutName)
    }
  },
  methods: {
    checkRouteLayout (incomingRoute) {
      const applicableRoute = incomingRoute.matched.find(r => r.meta.layout)
      const selectedRoute = applicableRoute === undefined ? defaultLayout : applicableRoute.meta.layout

      this.layoutName = selectedRoute
    }
  },
  mounted () {
    this.checkRouteLayout(this.$route)
  }
}
</script>
