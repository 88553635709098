const TOKEN_LOCAL_STORAGE_NAME = 'auth-token'

const state = () => ({
  user: null,
  authToken: null
})

const getters = {
  user: (state, getters, rootState) => state.user,
  authToken: (state, getters, rootState) => state.authToken,
  isLoggedIn: (state, getters, rootState) => state.user !== null,
  isAdmin: (state, getters, rootState) => state.user !== null && state.user.role === 'admin',
  isSupervisor: (state, getters, rootState) => state.user !== null && (state.user.role === 'admin' || state.user.role === 'supervisor')
}

const actions = {
  async initialise ({ commit }) {
    commit('clearUser')

    console.log('checking for cached auth token...')
    const storedAuthToken = window.localStorage.getItem(TOKEN_LOCAL_STORAGE_NAME)
    if (storedAuthToken) {
      console.log('testing cached auth token...')
      try {
        await API.auth.check_auth(storedAuthToken)
        commit('setAuthToken', storedAuthToken)

        console.log('token is valid')
        commit('setUser', await API.self.get_self())
      } catch {
        console.log('token is NOT valid')
        window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_NAME)
      }
    } else {
      console.log('no cached token')
    }
  },
  async authenticate ({ commit, state }, credentials) {
    const result = await API.auth.authenticate_user(credentials)

    if (result.result === 'accept') {
      const token = result.token
      commit('setAuthToken', token)
      window.localStorage.setItem(TOKEN_LOCAL_STORAGE_NAME, token)

      commit('setUser', await API.self.get_self())

      return result
    } else if (result.result === 'enrol') {
      return result
    } else {
      throw new Error('Unsupport authentication result')
    }
  },
  async logout ({ commit }) {
    commit('clearUser')
    commit('clearAuthToken')

    window.localStorage.removeItem(TOKEN_LOCAL_STORAGE_NAME)
    return Promise.resolve()
  },
  async changePassword ({ commit }, args) {
    await API.self.change_password(args.old, args.new)
  },
  async forgottenPassword ({ commit }, args) {
    await API.auth.forgotten_password(args)
  },
  async resetPassword ({ commit }, args) {
    await API.auth.reset_password(args)
  }
}

const mutations = {
  setUser (state, v) {
    state.user = v
  },
  setAuthToken (state, v) {
    state.authToken = v
  },
  clearUser (state) {
    state.user = null
  },
  clearAuthToken (state) {
    state.authToken = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
