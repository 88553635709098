import requests from './requests'

export default {
  get_options () {
    return requests.get('guest-access')
  },
  update_options (o) {
    return requests.put('guest-access', o)
  },
  reveal_password () {
    return requests.get('guest-access/password')
  },
  change_password (o) {
    return requests.put('guest-access/password', o)
  },
  get_activity () {
    return requests.get('guest-access/activity')
  },
  disconnect_all () {
    return requests.post('guest-access/activity/disconnect-all')
  }
}
