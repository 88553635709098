import requests from './requests'

export default {
  authenticate_user (credentials) {
    return requests.post('auth/user', credentials)
  },
  check_auth (token) {
    return requests.post('auth/check', { token })
  },
  forgotten_password (o) {
    return requests.post('auth/password/forgotten', o)
  },
  reset_password (o) {
    return requests.post('auth/password/reset', o)
  }
}
