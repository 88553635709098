const state = () => ({
  services: []
})

const getters = {
  services: (state, getters, rootState) => state.services
}

const actions = {
  async loadServices ({ commit }) {
    commit('setServices', await API.self.get_services())
  }
}

const mutations = {
  setServices (state, v) {
    state.services = v
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
