import requests from './requests'

export default {
  get_self () {
    return requests.get('self')
  },
  get_services () {
    return requests.get('self/services')
  },
  change_password (oldPassword, newPassword) {
    return requests.post('self/password', { old: oldPassword, new: newPassword })
  },
  get_activity () {
    return requests.get('self/activity')
  }
}
