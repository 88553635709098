import requests from './requests'

export default {
  get_challenge (site) {
    return requests.get(`guest-portal/${site}/challenge`)
  },
  login_via_password (site, credentials) {
    return requests.post(`guest-portal/${site}/login/password`, credentials)
  }
}
