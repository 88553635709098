import accounts from './accounts'
import auth from './auth'
import self from './self'
import tenants from './tenants'
import guest from './guest'
import guestLogin from './guest-login'
import logs from './logs'

export default {
  accounts,
  auth,
  self,
  tenants,
  guest,
  logs,
  guestLogin
}
