import requests from './requests'

export default {
  get_system () {
    return requests.get('logs/system')
  },
  get_auth (off, lim) {
    return requests.get('logs/auth' + ((off !== undefined) ? `?offset=${off}&limit=${lim}` : ''))
  },
  async count_auth () {
    return (await requests.get('logs/auth?count')).count
  }
}
