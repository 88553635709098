const state = () => ({
  tenant: null
})

const getters = {
  tenant: (state, getters, rootState) => state.tenant
}

const actions = {
  async initialise ({ commit }, tenantCode) {
    commit('setTenant', await API.tenants.get(tenantCode))
  }
}

const mutations = {
  setTenant (state, v) {
    state.tenant = v
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
