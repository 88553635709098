const state = () => ({
  dialogComponent: null
})

const getters = {
}

const actions = {
  async registerDialogComponent ({ commit }, component) {
    commit('setDialogComponent', component)
  },
  showMessage ({ state }, { title, message, buttons }) {
    return state.dialogComponent.show(title, message, buttons)
  },
  showInfo ({ state }, { title, message }) {
    return state.dialogComponent.show(title, message, [{ result: true, text: 'OK', variant: 'primary' }])
  },
  showError ({ state }, { title, message }) {
    return state.dialogComponent.show(title, message, [{ result: true, text: 'OK', variant: 'primary' }])
  },
  showConfirm ({ state }, { title, message }) {
    return state.dialogComponent.show(title, message, [{ result: true, text: 'Yes', variant: 'primary' }, { result: false, text: 'No', variant: 'secondary' }])
  }
}

const mutations = {
  setDialogComponent (state, v) {
    state.dialogComponent = v
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
