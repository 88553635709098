const state = () => ({
  accounts: []
})

const getters = {
  list: (state, getters, rootState) => state.accounts
}

const actions = {
  async load ({ commit }) {
    commit('setAccounts', await API.accounts.list())
  }
}

const mutations = {
  setAccounts (state, v) {
    state.accounts = v
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
