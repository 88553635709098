import requests from './requests'

export default {
  list () {
    return requests.get('accounts')
  },
  get (id) {
    return requests.get(`accounts/${id}`)
  },
  update (obj) {
    return requests.put(`accounts/${obj.id}`, obj)
  },
  create (obj) {
    return requests.post('accounts', obj)
  },
  delete (obj) {
    return requests.delete(`accounts/${obj.id}`)
  },
  reset_password (account, operation) {
    return requests.post(`accounts/${account.id}/reset-password`, operation)
  },
  get_auth_log (account) {
    return requests.get(`accounts/${account.id}/log/auth`)
  },
  get_acct_log (account) {
    return requests.get(`accounts/${account.id}/log/acct`)
  },
  services: {
    update (account, o) {
      return requests.put(`accounts/${account.id}/services`, o)
    }
  }
}
